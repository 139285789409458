import { lazy } from "react";

export const CustomerDetails = lazy(() =>
  import("features/Crm/modules/CustomerDetails/CustomerDetails").then(
    (module) => ({ default: module.CustomerDetails })
  )
);

export const LeadActions = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Actions/modules/LeadActions/LeadActions"
  ).then((module) => ({ default: module.LeadActions }))
);

export const CustomActions = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Actions/modules/CustomActions/CustomActions"
  ).then((module) => ({ default: module.CustomActions }))
);

export const ExlyDeleteModal = lazy(() =>
  import("features/Common/modules/ExlyDeleteModal/ExlyDeleteModal").then(
    (module) => ({ default: module.ExlyDeleteModal })
  )
);

export const AddTagModal = lazy(() =>
  import("webpage-leads/components/CrmModals/AddTagsModal/AddTagModal")
);

export const AssigneeEmptyState = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Actions/modules/AssigneeEmptyState/AssigneeEmptyState"
  ).then((module) => ({ default: module.AssigneeEmptyState }))
);

export const AdditionalDetails = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Info/modules/AdditionalDetails/AdditionalDetails"
  ).then((module) => ({ default: module.AdditionalDetails }))
);
export const EditInfoPopup = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Info/modules/EditDetailsPopups/EditInfoPopup"
  ).then((module) => ({ default: module.EditInfoPopup }))
);
export const ConfirmationPopup = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Info/modules/EditDetailsPopups/ConfirmationPopup"
  ).then((module) => ({ default: module.ConfirmationPopup }))
);

export const ChangeRequest = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Info/modules/ChangeRequest/ChangeRequest"
  ).then((module) => ({ default: module.ChangeRequest }))
);

export const CancelChangePopup = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Info/modules/EditDetailsPopups/CancelChangePopup"
  ).then((module) => ({ default: module.CancelChangePopup }))
);

export const ExlyTooltip = lazy(() => import("ui/modules/Tooltip"));

export const AddNoteModal = lazy(() =>
  import("webpage-leads/components/CrmModals/AddNotesModal/AddNoteModal")
);

export const ChangeExpiryPopup = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/ExpandableLogs/PurchaseLog/ChangeExpiryPopup/ChangeExpiryPopup"
  ).then((module) => ({ default: module.ChangeExpiryPopup }))
);

export const PurchaseDetails = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/ExpandableLogs/PurchaseLog/PurchaseDetails"
  ).then((module) => ({ default: module.PurchaseDetails }))
);

export const Responses = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/ExpandableLogs/FormLog/Responses"
  ).then((module) => ({ default: module.Responses }))
);

export const Media = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/Common/Media/Media"
  ).then((module) => ({ default: module.Media }))
);

export const FileAttachement = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/Common/FileAttachement/FileAttachment"
  ).then((module) => ({ default: module.FileAttachement }))
);

export const MessageLog = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/CommunicationLog/MessageLog"
  ).then((module) => ({ default: module.MessageLog }))
);
export const EmailLog = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/CommunicationLog/EmailLog/EmailLog"
  ).then((module) => ({ default: module.EmailLog }))
);
export const CommunicationInfoPopup = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/CommunicationLog/CommunicationInfoPopup/InfoPopup"
  ).then((module) => ({ default: module.CommunicationInfoPopup }))
);

export const EmptyMediaResponse = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/Common/Media/EmptyMediaResponse"
  ).then((module) => ({ default: module.EmptyMediaResponse }))
);

export const ReactionChip = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/CommunicationLog/ReactionChip/ReactionChip"
  ).then((module) => ({ default: module.ReactionChip }))
);

export const UnsupportedMedia = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/Common/Media/modules/UnsupportedMedia/UnsupportedMedia"
  ).then((module) => ({ default: module.UnsupportedMedia }))
);
export const WhatsappLocation = lazy(() =>
  import(
    "features/Crm/modules/CustomerDetails/modules/Timeline/modules/TimelineLogs/modules/CommunicationLog/WhatsappLocation/WhatsappLocation"
  ).then((module) => ({ default: module.WhatsappLocation }))
);
