import CircularProgress from "@material-ui/core/CircularProgress";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { FEATURE_CREDITS_API_KEYS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import {
  PLAN_SECTION_TYPES,
  PLAN_SECTION_TYPES_COMPONENTS,
} from "features/MySubscriptions/modules/ActivePlanSection/constants/ActivePlanSection.constants";
import React from "react";

export const OrgMemberCredits = ({
  data: orgCreditsDetails,
  isLoading,
  isSuccess,
  isError,
  error,
}) => {
  const {
    [FEATURE_CREDITS_API_KEYS.active_staff_count]: activeStaffCount,
    [FEATURE_CREDITS_API_KEYS.total_credits]: totalCredits,
  } = orgCreditsDetails ?? {};

  const creditsConfig = [
    {
      type: PLAN_SECTION_TYPES.PRIMARY,
      label: "Active Staff",
      value: activeStaffCount,
      classes: { label: "!tw-opacity-100", value: "!tw-text-[#009966]" },
    },
    {
      type: PLAN_SECTION_TYPES.PRIMARY,
      label: "Total Limit",
      value: totalCredits,
      classes: { label: "!tw-opacity-100" },
    },
  ];

  if (isLoading) {
    return <CircularProgress className="!tw-size-5" />;
  }

  if (isSuccess) {
    return (
      <div className="max-md:tw-min-h-0 max-md:tw-w-full tw-flex tw-gap-9 max-md:tw-gap-2 tw-justify-around tw-px-4 tw-py-3 tw-rounded-[10px] tw-border-[0.75px] tw-border-[#D9D9D9] tw-border-solid">
        {creditsConfig.map((config, index) => {
          const Component = PLAN_SECTION_TYPES_COMPONENTS[config.type];
          return config.hide ? null : (
            <>
              <Component key={config.type} {...config} />

              {index < creditsConfig.length - 1 && (
                <div className="tw-w-[0.75px] tw-bg-[#D9D9D9] tw-self-stretch" />
              )}
            </>
          );
        })}
      </div>
    );
  }

  if (isError) {
    return <div>{error?.message || SOMETHING_WENT_WRONG}</div>;
  }

  return null;
};
