import constants from "constants/constants";
import { PAYMENT_DEBIT_MODES_LABELS } from "features/Listings/modules/PricingSection/PricingSection.constants";
import moment from "moment";
import {
  field_data,
  FILTERS_OPTIONS_PLACEHOLDERS,
  form_data,
  listingTypeOptions,
  ORDER_PAYMENT_TYPE_FILTER_CHIPS_LABELS,
  REFUND_HISTORY_TAB,
  tab_config,
} from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import {
  GATEWAY_TYPE_OPTIONS_MAP,
  TRANSACTION_TYPE_ENUM,
} from "../Transactions.constants";
import { isInternationalTimezone } from "utils/AuthUtil";

/**
 * Checks if a transaction is a cash booking transaction.
 * @param {number} transactionType - The type of transaction to check.
 * @returns {boolean} Returns true if the transaction type is 'offline'; otherwise, returns false.
 */
export const isCashBookingTransaction = (transactionType) =>
  transactionType === TRANSACTION_TYPE_ENUM.offline;

export const formatGuestName = (value) => `${form_data.name}: ${value}`;

export const formatGuestEmail = (value) => `${form_data.email}: ${value}`;

export const formatListingTitle = (value) =>
  `${form_data.session_name}: ${value}`;

export const formatOfferingVariation = (value) =>
  `${form_data.offeringVariation.label}: ${value}`;

export const formatGuestPhoneNumber = (value) => `${form_data.phone}: ${value}`;

export const formatMinPrice = (value) => `${form_data.min_price}: ${value}`;

export const formatMaxPrice = (value) => `${form_data.max_price}: ${value}`;

export const formatListingType = (value) => {
  const listing = listingTypeOptions?.find((item) => item.id == value);
  return `Offering Type: ${listing?.name}`;
};

export const formatPaymentType = (value) =>
  `${form_data.payment_plan_type}: ${ORDER_PAYMENT_TYPE_FILTER_CHIPS_LABELS[value]}`;

export const formatPaymentDebitMode = (value) =>
  `${form_data.payment_debit_mode}: ${PAYMENT_DEBIT_MODES_LABELS[value]}`;

export const formatOrgUuid = (staffMembers) => (value) => {
  const staff = staffMembers?.find((item) => item.id == value);
  return `${form_data.staff}: ${staff?.name}`;
};

export const formatStartDate = (value) => {
  if (!value) return "N/A";
  return `${form_data.start_date}: ${moment(value).format(
    constants.displayDateYearFormat
  )}`;
};

export const formatEndDate = (value) => {
  if (!value) return "N/A";
  return `${form_data.end_date}: ${moment(value).format(
    constants.displayDateYearFormat
  )}`;
};

export const formatTransactionId = (value) =>
  `${FILTERS_OPTIONS_PLACEHOLDERS[field_data.transaction_id]}: ${value}`;

export const formatGatewayType = (value) =>
  `${FILTERS_OPTIONS_PLACEHOLDERS[field_data.gateway_type]}: ${
    GATEWAY_TYPE_OPTIONS_MAP[value].label
  }`;

export const getTransactionsTabConfig = () => {
  const isInternational = isInternationalTimezone();
  return [...tab_config, ...(!isInternational ? REFUND_HISTORY_TAB : [])];
};
