import { InputAdornment } from "@material-ui/core";
import { Card, Input, Shimmer } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import { ExlyNote } from "common/Components/ExlyNote/ExlyNote";
import { ExlyDropdown } from "common/form";
import { formKeys } from "features/Crm/modules/Transactions/Transactions.constants";
import { isCashBookingTransaction } from "features/Crm/modules/Transactions/utils/Transactions.utils";
import React from "react";
import { InitiateRefundModalContext } from "ui/pages/transactions/AllTransaction/AllTransaction";
import { TRANSACTIONS_REFUND_OPTIONS } from "ui/pages/transactions/AllTransaction/transactions.constants";
import { isCustomRefundAmountType } from "ui/pages/transactions/AllTransaction/utils/AllTransaction.util";
import { FIELD_DATA } from "ui/pages/transactions/RefundHistory/refund.constant";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import styles from "../../Refund.module.css";
import { NOTE_INFO, REFUND_OPTIONS_VALUES } from "./InitiateRefund.constants";
import moduleStyles from "./InitiateRefund.module.css";
import { handleRefundAmountChange } from "./utils/InitiateRefund.utils";

export const InitiateRefund = () => {
  const {
    refundAmountData,
    refundButtonDisabled,
    selectedRefundType,
    handleRefundOptionsSelection,
    customRefundAmountState,
    handleSetCustomRefundAmount,
    selectedRefundRecords,
    validateRefunds,
  } = React.useContext(InitiateRefundModalContext);

  const selectedRefundRecordsArray = Object.values(selectedRefundRecords);
  const isSingleRowSelected = selectedRefundRecordsArray.length === 1;
  const [firstSelectedRefundRecord] = selectedRefundRecordsArray;
  const {
    [formKeys.transactionType.key]: firstSelectedRefundRecordTransactionType,
    [FIELD_DATA.transaction_amount]: transactionAmount,
    [FIELD_DATA.is_international]: firstSelectedRefundTransactionIsIntl,
  } = firstSelectedRefundRecord ?? {};
  const isCustomRefund = isCustomRefundAmountType(
    TRANSACTIONS_REFUND_OPTIONS[selectedRefundType]
  );
  const showCustomRefund =
    isSingleRowSelected &&
    !isCashBookingTransaction(firstSelectedRefundRecordTransactionType) &&
    transactionAmount > 0;

  // We only select one international transaction at a time.
  // So, picking the first international transaction.
  // refer getFilteredRowsFromSelection in src/ui/pages/transactions/AllTransaction/hooks/useTransactionUpdate.js
  const allowOnlyCustomRefund = firstSelectedRefundTransactionIsIntl;

  const isLoading = refundButtonDisabled && !isCustomRefund;

  const {
    no_of_refundable_transactions: bookingsForRefund,
    refund_amount: refundAmount,
  } = refundAmountData;

  const handleChangeCustomRefundAmount = (e) => {
    const value = e?.target?.value;
    handleSetCustomRefundAmount(value);
    handleRefundAmountChange({
      refundAmount: +value,
      validateRefunds,
      selectedRefundType,
      selectedRefundRecords,
    });
  };

  const handleBlur = (e) => {
    const {
      target: { value },
    } = e;
    handleSetCustomRefundAmount(value);
  };

  return (
    <>
      <ExlyDropdown
        minWidth={"100%"}
        meta={{}}
        label="Select refund type"
        input={{
          value: selectedRefundType,
          onChange: handleRefundOptionsSelection,
        }}
        size="small"
        placeholder="Select refund type"
        fullWidth
        options={REFUND_OPTIONS_VALUES({
          showCustomRefund,
          allowOnlyCustomRefund,
        })}
      />
      {selectedRefundType ? (
        <>
          <div
            className={classnames(
              styles.gapContainer,
              "d-flex justify-content-between my-3 font_md"
            )}
          >
            <Card className={styles.cardContainer}>
              <span className={styles.headingText}>Booking selected:</span>
              {isLoading ? (
                <Shimmer
                  width="100px"
                  height="17px"
                  borderRadius="8px"
                  wrapper_classname={moduleStyles.shimmer}
                />
              ) : (
                <strong>{isCustomRefund ? 1 : bookingsForRefund}</strong>
              )}
            </Card>
            <Card className={styles.cardContainer}>
              <span className={classnames(styles.headingText, "mb-1")}>
                Refund amount:
              </span>
              {isLoading ? (
                <Shimmer width="100px" height="17px" borderRadius="8px" />
              ) : (
                <>
                  {isCustomRefund ? (
                    <Input
                      size="thin"
                      type="number"
                      placeholder="Enter refund amount"
                      inputElementClassName={styles.refundAmountInput}
                      startAdornment={
                        <InputAdornment
                          className={styles.currencySymbol}
                          position="start"
                        >
                          {getUserCurrencySymbol()}
                        </InputAdornment>
                      }
                      helperText={customRefundAmountState.error}
                      onChange={handleChangeCustomRefundAmount}
                      onBlur={handleBlur}
                      {...customRefundAmountState}
                    />
                  ) : (
                    <strong>₹ {parseFloat(refundAmount)}</strong>
                  )}
                </>
              )}
            </Card>
          </div>
          <ExlyNote label={NOTE_INFO} />
        </>
      ) : null}
    </>
  );
};
