export const NOTE_INFO =
  "Note: If any selected transaction is part of bulk booking, then all transactions in the booking will be refunded to the customer and not only the selected transaction.";

export const CUSTOM_REFUND_OPTION = {
  id: "3",
  value: "REFUND_CUSTOM_AMOUNT",
  label: "Custom refund amount",
};

export const REFUND_OPTIONS_VALUES = ({
  showCustomRefund,
  allowOnlyCustomRefund,
}) =>
  allowOnlyCustomRefund
    ? [CUSTOM_REFUND_OPTION]
    : [
        {
          id: "1",
          value: "REFUND_LISTING_PRICE",
          label: "Refund complete amount as paid by your customers",
        },
        {
          id: "2",
          value: "CHARGE_IHF_TO_CUSTOMERS",
          label:
            "Refund after deducting payment gateway charges from the amount paid by your customers",
        },
        ...(showCustomRefund ? [CUSTOM_REFUND_OPTION] : []),
      ];
